import {Tag,Select,Option,Input,Button,Pagination,Form,FormItem,DatePicker,Table,TableColumn,Radio,Dialog } from 'element-ui';
import {getList} from '../api/submission-list';
import {timestampToTime} from '../util/index.js';

export default {
  name:'submission-list',
  components:{
    elRadio:Radio,
    elTag:Tag,
    elSelect:Select,
    elOption:Option,
    elInput:Input,
    elButton:Button,
    elPagination:Pagination,
    elForm:Form,
    elFormItem:FormItem,
    elDatePicker:DatePicker,
    elDialog:Dialog,
    elTable:Table,
    elTableColumn:TableColumn
  },
  data(){
    this.taskid = this.$route.query.id;
    this.index = Number(this.$route.query.index);
    this.timestampToTime = timestampToTime;
    this.formLabelWidth = '80px';
    this.statusmap = {
      'to_be_modified':'待修改',
      'pass':'通过'
    }
    this.limit=20;
    return {
      reviewInfo:false,
      reviewVisible:false,
      list:[
        {
          name:'任务一',
          date:'2020-09-08 08:00:00',
          status:'pass',
          code:'',
          review:{
            images:[1,2],
            text:'good',
            rate:4,
            audio:""
          }
        },
        // {
        //   name:'进阶课',
        //   time:'2020-09-08 08:00:00',
        //   status:''
        // }
      ]
    }
  },
  async mounted(){
    this.getList();
  },
  methods:{

   onSeeCode(item){
      const {task:{id:taskid},id:submissionid} = item;
      window.open(`./scratch3/task.html?taskid=${taskid}&submissionid=${submissionid}`);        
    },
    onModifyTask(taskid,submissionid){
      window.open(`./scratch3/task.html?taskid=${taskid}&submissionid=${submissionid}`);        
    },
    onSeeReview(item){
      this.reviewInfo = item;
      this.reviewVisible = true;
    },
    async getList(){
      const res = await getList(this.taskid); 
      if(res.error === null){
        this.list = res.data ||[];
      }
    },
  }
}