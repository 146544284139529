// 学情记录
import {get} from '../util/request.js';
import {showErrorTip} from '../util/index.js'

const getList = async (id) => {
  let url='/api/UserCourseTaskSubmissionAPI';
  let data = {
    task_id:id
  }
  const res = await get({
    url,
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}



export {
  getList,
  
}